<template>
  <div id="app">
    <label>
      Type your message here<br><small>(letters only for now)</small>
      <input
        class="input"
        type="text"
        value="alphabet"
        @input="(e) => updateWords(e.target.value)"
      />
    </label>
    <label>
      Adjust letter size
      <input
        class="slider"
        type="range"
        min="40"
        max="120"
        v-model="letterHeight"
      >
    </label>
    <div
      v-if="words.length"
      class="words"
    >
      <div
        v-for="(word, index) in words"
        :key="index"
        class="word"
        :style="wordStyle"
      >
        <div
          v-for="(letter, index2) in word"
          :key="index2"
          class="letter"
          :style="letterStyle"
        >
          <img
            :src="`/letters/${letter}.png`"
            :alt="letter"
            :height="letterHeight"
          >
        </div>
      </div>
    </div>

    <div class="buttons">
      <div class="button-label">Save as:</div>
      <button @click="saveImage(false)">JPG</button>
      <button @click="saveImage(true)">PNG</button>
    </div>
  </div>
</template>

<script>
  import htmlToImage from 'html-to-image';
  import download from 'downloadjs';

  export default {
    name: 'App',
    data() {
      return {
        letterHeight: 60,
        words: [],
      };
    },
    mounted() {
      setTimeout(() => {
        const input = document.querySelector('.input')
        input.focus();
        this.updateWords(input.value);
      }, 50);
    },
    methods: {
      updateWords(str) {
        const lettersFiltered = str.toUpperCase().split('').filter((letter) => /[A-Z\ ]/g.test(letter)).join('');
        const words = lettersFiltered.split(' ');
        words.forEach((word) => word.split(''));
        this.words = words;
      },
      saveImage(transparent = false) {
        const node = document.querySelector('.words');

        let promise = null;
        let ext = 'jpg';

        if (transparent) {
          promise = htmlToImage.toPng(node);
          ext = 'png';
        } else {
          node.style.backgroundColor = 'white';
          promise = htmlToImage.toJpeg(node, { quality: 0.95 });
        }
        promise
          .then((dataUrl) => {
            node.style.backgroundColor = '';
            download(dataUrl, `alphabet.${ext}`);
          })
          .catch((error) => {
            console.error('oops, something went wrong!', error);
          });
      },
    },
    computed: {
      letterStyle() {
        const margin = Math.round(this.letterHeight / 20);
        return {
          margin: `0 ${margin}px`,
        };
      },
      wordStyle() {
        const margin = Math.round(this.letterHeight / 4);
        return {
          margin: `${margin}px`,
        };
      },
    },
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Chilanka&display=swap');

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: 0;
  }
  html,
  body {
    font-family: 'Chilanka', cursive;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  #app {
    height: 100%;
    margin: 0 auto;
    max-width: 800px;
    padding: 0 15px;
    width: 100%;
  }
  label {
    cursor: pointer;
    display: block;
    font-size: 20px;
    line-height: 1.4;
    margin-top: 30px;
    text-align: center;
  }
  .input {
    border-radius: 4px;
    border: 1px solid grey;
    color: black;
    font-size: 20px;
    height: 44px;
    line-height: 44px;
    margin: 10px 0;
    padding: 0 15px;
    text-align: center;
    width: 100%;
  }
  .slider {
    margin: 10px 0 60px 0;
    width: 100%;
  }
  .words {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .word {
      display: flex;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    .button-label {
      font-size: 20px;
      line-height: 48px;
      margin-right: 10px;
    }
    button {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: none;
      color: black;
      cursor: pointer;
      display: inline-block;
      font-family: 'Chilanka', cursive;
      font-size: 18px;
      height: 48px;
      letter-spacing: 1px;
      line-height: 54px;
      margin: 0 10px;
      padding: 0 20px;
      text-align: center;
      text-transform: uppercase;
      transition: background-color 200ms ease-in-out;
      white-space: nowrap;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
</style>
